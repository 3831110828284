import { Space } from 'antd';
import React, { CSSProperties } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  InteractionMode,
  TooltipItem,
  ChartType,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { commafy } from '../../../../utils/text';
import ContentCard from '../../ContentCard';
import { getCustomTooltip, ICustomTooltip } from '../index';
import ChartLegend from '../Legend';
import { IBarChartData } from '../types';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

interface IGetOptions {
  stacked: boolean;
  mode: InteractionMode;
  reversed: boolean;
  showTotal?: boolean;
  suffix?: string;
  prefix?: string;
}

export const getOptions = ({ stacked, mode, reversed, showTotal, prefix, suffix }: IGetOptions) => ({
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
      intersect: false,
      external: (context: ICustomTooltip) =>
        getCustomTooltip({ context, mode, isReversed: reversed, showTotal, prefix, suffix }),
      mode,
      callbacks: {
        label: (item: TooltipItem<ChartType>) => `${item.dataset.label}, ${item.raw}, ${item.dataset.backgroundColor}`,
      },
    },
  },
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      stacked,
      ticks: {
        color: '#8D94A2',
      },
    },
    y: {
      stacked,
      ticks: {
        color: '#8D94A2',
        callback: (value: string | number) => `$${commafy(value)}`,
      },
    },
  },
});

interface IBarChart extends CSSProperties {
  data?: IBarChartData;
  children?: React.ReactNode;
  legendControls?: React.ReactNode;
  hideLegend?: boolean;
  stacked?: boolean;
  mode?: InteractionMode;
  reversed?: boolean;
  loading?: boolean;
  showTotal?: boolean;
  suffix?: string;
  prefix?: string;
}

const BarChart = ({
  data,
  children,
  hideLegend,
  legendControls,
  stacked = false,
  mode = 'index',
  reversed = false,
  loading = false,
  showTotal = false,
  suffix,
  prefix = '$',
  ...styles
}: IBarChart) => (
  <Space direction="vertical" size={20}>
    {!hideLegend && data && (
      <ChartLegend data={data} reversed={reversed}>
        {legendControls}
      </ChartLegend>
    )}

    {children}

    <ContentCard loading={loading}>
      {data && (
        <div className="chart-wrapper" style={styles}>
          <Bar options={getOptions({ stacked, mode, reversed, showTotal, suffix, prefix })} data={data} />
        </div>
      )}
    </ContentCard>
  </Space>
);

BarChart.defaultProps = {
  data: undefined,
  children: undefined,
  legendControls: undefined,
  hideLegend: false,
  stacked: false,
  mode: 'index',
  reversed: false,
  loading: false,
  showTotal: false,
  prefix: undefined,
  suffix: undefined,
};

export default BarChart;
