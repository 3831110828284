import { Chart as ChartJS, ChartType, InteractionMode, TooltipModel } from 'chart.js';
import { commafy, getNumbers, toFixed } from '../../../utils/text';

export interface ICustomTooltip {
  tooltip: TooltipModel<ChartType>;
  chart: ChartJS<ChartType>;
}

interface IGetCustomTooltip {
  context: ICustomTooltip;
  mode: InteractionMode;
  isReversed: boolean;
  showTotal?: boolean;
  suffix?: string;
  prefix?: string;
}

export const getCustomTooltip = ({ context, mode, isReversed, showTotal, suffix, prefix }: IGetCustomTooltip) => {
  let tooltipEl = document.getElementById('chartjs-tooltip');

  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.id = 'chartjs-tooltip';
    tooltipEl.innerHTML = '<div id="chartjs-tooltip-content"></div>';
    document.body.appendChild(tooltipEl);
  }

  const tooltipModel = context.tooltip;

  if (tooltipModel.opacity === 0) {
    tooltipEl.style.opacity = '0';

    return;
  }

  const getBody = (bodyItem: { lines: string[] }) => bodyItem.lines;

  if (context.tooltip.body) {
    const titleLines = tooltipModel.title || [];
    const tooltipBody = context.tooltip.body.map(getBody);
    const bodyLines = isReversed ? [...tooltipBody].reverse() : [...tooltipBody];
    let innerHtml = '<div id="tooltip" class="tooltip"><div class="tooltip-content">';

    if (mode === 'point' || mode === 'nearest') {
      bodyLines.forEach((body: string[]) => {
        const [label, value] = body[0].split(', ') || [];
        const preparedValue = getNumbers(value);
        const labelEl = `<span class="color-dark fw-500">${label}</span>`;
        const valueEl =
          prefix === '$'
            ? `<span class="color-gray">$${commafy(preparedValue)}${suffix || ''}</span>`
            : `<span class="color-gray">${prefix || ''}${toFixed(preparedValue, 2)}${suffix || ''}</span>`;
        const titleEl = `<span class="color-gray">${titleLines[0]}</span>`;

        innerHtml += '<div class="gap-4">';
        innerHtml += `<div>${labelEl}</div>`;
        innerHtml += `<div style="display: flex; align-items: center; gap: 8px">${titleEl} - ${valueEl}</div>`;
        innerHtml += '</div>';
      });
    } else {
      bodyLines.forEach((body: string[]) => {
        const [label, value, color] = body[0].split(', ') || [];
        const preparedValue = getNumbers(value);
        const marker = `<div style="border-radius: 50%; width: 10px; height: 10px; background: ${color}"></div>`;
        const labelEl = `<span class="color-gray flex-1">${label}:</span>`;
        const valueEl =
          prefix === '$'
            ? `<span class="color-dark fw-500" style="min-width: 50px; text-align: right">$${commafy(preparedValue)}${
                suffix || ''
              }</span>`
            : `<span class="color-dark fw-500" style="min-width: 50px; text-align: right">
                ${prefix || ''}${toFixed(preparedValue, 2)}${suffix || ''}
              </span>`;

        innerHtml += `<div style="display: flex; align-items: center; gap: 8px">${marker} ${labelEl} ${valueEl}</div>`;
      });
    }

    if (showTotal) {
      innerHtml += `<div style="width: 100%; height: 1px; background: var(--color-border)"></div>`;

      const total = bodyLines
        .map((line) => getNumbers(line[0].split(', ')[1]))
        .reduce((acc, currentValue) => acc + parseFloat(currentValue), 0);
      const labelEl = `<span class="color-dark flex-1 fw-600">Total:</span>`;
      const valueEl =
        prefix === '$'
          ? `<span class="color-dark fw-500" style="min-width: 50px; text-align: right">$${commafy(total)}${
              suffix || ''
            }</span>`
          : `<span class="color-dark fw-500" style="min-width: 50px; text-align: right">${prefix || ''}${toFixed(
              total,
              2
            )}${suffix || ''}</span>`;

      innerHtml += `<div style="display: flex; align-items: center; gap: 8px">${labelEl} ${valueEl}</div>`;
    }

    innerHtml += '</div></div>';

    const tooltipContent = tooltipEl.querySelector('#chartjs-tooltip-content');

    if (tooltipContent) {
      tooltipContent.innerHTML = innerHtml;
    }
  }
  const tooltip = document.getElementById('tooltip');
  const position = context.chart.canvas.getBoundingClientRect();

  tooltip?.classList.remove('arrow-right', 'arrow-left');

  const left = position.left + tooltipModel.caretX - tooltipEl.offsetWidth / 2;
  let positionLeft = left;

  if (window.innerWidth - left < tooltipEl.offsetWidth) {
    tooltip?.classList.add('arrow-right');
    positionLeft = left - tooltipEl.offsetWidth / 2 + 30;
  }

  if (left < 0) {
    tooltip?.classList.add('arrow-left');
    positionLeft = left + tooltipEl.offsetWidth / 2 - 30;
  }

  tooltipEl.style.opacity = '1';
  tooltipEl.style.position = 'absolute';
  tooltipEl.style.left = `${positionLeft}px`;
  tooltipEl.style.top = `${position.top + tooltipModel.caretY}px`;
  tooltipEl.style.padding = '8px 12px';
  tooltipEl.style.pointerEvents = 'none';
  tooltipEl.style.transition = '.3s all';
};
