import { Col, Form as AntdForm, Input, Row } from 'antd';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import React, { Dispatch, SetStateAction } from 'react';
import { useContextUsers } from '../../../../../context/users';
import ImageUpload from '../../../../Common/ImageUpload';
import Section from '../Section';

interface ICompanyInfo {
  editModeState: [string | null, Dispatch<SetStateAction<string | null>>];
  handleFileUpload: (name: string, data: string) => void;
}
const CompanyInfo = ({ editModeState, handleFileUpload }: ICompanyInfo) => {
  const sectionName = 'companyInfo';
  const form = useFormInstance();
  const [editMode, setEditMode] = editModeState;

  const { profile, profileUpdateLoading } = useContextUsers();

  const disabled = editMode !== sectionName;

  const handleEditModeChange = (isActive: boolean) => {
    setEditMode(isActive ? sectionName : null);
  };

  return (
    <Section
      form={form}
      label="Company info"
      description="Update your company logo and company details."
      editable
      isEditModeActive={editMode === sectionName}
      handleEditModeChange={handleEditModeChange}
      controlsDisabled={!!editMode && editMode !== sectionName}
      controlsLoading={profileUpdateLoading}
    >
      <Row gutter={20}>
        <Col span={24}>
          <AntdForm.Item name="companyName" label="Company Name">
            <Input size="large" placeholder="Enter Company Name" disabled={disabled} />
          </AntdForm.Item>
        </Col>
        <Col span={24}>
          <AntdForm.Item name="websiteLink" label="Website">
            <Input size="large" placeholder="Enter Website" disabled={disabled} />
          </AntdForm.Item>
        </Col>
        <Col span={24}>
          <AntdForm.Item name="nmlsId" label="NMLS #">
            <Input size="large" placeholder="Enter NMLS #" disabled={disabled} />
          </AntdForm.Item>
        </Col>
        <Col span={24}>
          <AntdForm.Item name="address" label="Address">
            <Input size="large" placeholder="Enter Address" disabled={disabled} />
          </AntdForm.Item>
        </Col>
      </Row>
      <Row gutter={20}>
        <Col xl={12} span={24}>
          <ImageUpload
            fileName="logo.png"
            url={profile?.logo}
            label="Company Logo"
            cropShape="rect"
            placeholder="/logo.png"
            handleFileUpload={(data) => handleFileUpload('logo', data)}
          />
        </Col>
      </Row>
    </Section>
  );
};

export default CompanyInfo;
