import Icon from '@ant-design/icons';
import { Modal, ModalFuncProps, notification } from 'antd';
import { ArgsProps } from 'antd/es/notification';
import { IconType } from 'antd/lib/notification';
import React, { createContext, PropsWithChildren, ReactElement, useContext } from 'react';
import {
  Close,
  NotificationError,
  NotificationInfo,
  NotificationSuccess,
  NotificationWarning,
} from '../components/Common/Icon';
import { JsonResult } from '../types';

interface NotificationsContext extends JsonResult {
  openNotification?: (props: ArgsProps) => void;
  openConfirm?: (props: ModalFuncProps) => void;
}

const defaultValue = {
  openNotification: undefined,
};

export const NotificationsContext = createContext<NotificationsContext>(defaultValue);

const ICONS: { [key in IconType]: ReactElement | undefined } = {
  info: <Icon component={NotificationInfo} />,
  success: <Icon component={NotificationSuccess} />,
  error: <Icon component={NotificationError} />,
  warning: <Icon component={NotificationWarning} />,
};
const NotificationsProvider = ({ children }: PropsWithChildren) => {
  const [api, notificationsContextHolder] = notification.useNotification();
  const [modal, confirmContextHolder] = Modal.useModal();

  const openNotification = (props: ArgsProps) => {
    const { type = 'success', placement = 'topRight', icon, duration = 3 } = props;

    api[type]({
      ...props,
      placement,
      icon: icon || ICONS[type],
      duration,
      closeIcon: <Icon component={Close} />,
    });
  };

  const openConfirm = (props: ModalFuncProps) => {
    const { okText = 'Confirm', cancelText = 'Cancel' } = props;

    modal.confirm({
      icon: null,
      centered: true,
      okButtonProps: { size: 'large' },
      cancelButtonProps: { size: 'large' },
      ...props,
      okText,
      cancelText,
    });
  };

  return (
    <NotificationsContext.Provider
      value={{
        openNotification,
        openConfirm,
      }}
    >
      {notificationsContextHolder}
      {confirmContextHolder}
      {children}
    </NotificationsContext.Provider>
  );
};

export default NotificationsProvider;

export const useContextNotifications = (): NotificationsContext => useContext(NotificationsContext);
