import { Typography } from 'antd';
import React from 'react';
import styles from './index.module.less';

interface IAuth {
  title?: string;
  text?: string;
  children: React.ReactNode;
}

const Auth = ({ title, text, children }: IAuth) => (
  <section className={styles.wrapper}>
    <div className={styles.container}>
      <div className={styles.textBox}>
        <Typography.Title className={styles.title}>{title}</Typography.Title>
        <Typography.Text className={styles.text}>{text}</Typography.Text>
      </div>
      {children}
    </div>
  </section>
);

Auth.defaultProps = {
  title: undefined,
  text: undefined,
};

export default Auth;
