import { Tag } from 'antd';
import React from 'react';
import { capitalize } from '../../../../../utils/text';

const colors = {
  new: {
    text: 'var(--color-tag-blue)',
    background: 'var(--color-tag-light-blue)',
  },
  contacted: {
    text: 'var(--color-tag-green)',
    background: 'var(--color-tag-light-green)',
  },
};

interface IStatus {
  status: 'new' | 'contacted';
}

const Status = ({ status }: IStatus) => (
  <Tag color={colors[status].background} style={{ color: colors[status].text, padding: '4px 8px' }}>
    {capitalize(status)}
  </Tag>
);

export default Status;
