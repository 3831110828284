import { Divider, Space, Typography } from 'antd';
import { IReferralItem } from '../../../../../hooks/users';
import Status from '../Status';

interface IReferralRow {
  data: IReferralItem;
  isLast?: boolean;
}

const ReferralRow = ({ data, isLast }: IReferralRow) => (
  <>
    <Space direction="vertical" size={8}>
      <div className="flex-row flex-justify-space-between flex-align-start">
        <Space direction="vertical" size={4}>
          <Typography.Text>{data.fullName}</Typography.Text>
          <Typography.Text className="fs-075 color-gray">{data.email}</Typography.Text>
          <Typography.Text className="fs-075 color-gray">{data.phoneNumber}</Typography.Text>
        </Space>
        {data.status && <Status status={data.status} />}
      </div>
      <Typography.Text>{data.comment}</Typography.Text>
    </Space>
    {!isLast && <Divider />}
  </>
);

ReferralRow.defaultProps = {
  isLast: false,
};
export default ReferralRow;
