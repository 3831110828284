import Icon from '@ant-design/icons';
import { Button, Space, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import { Back } from '../../../Common/Icon';
import ReferralsTable from './Table';

const RecentReferrals = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate({ pathname: '/home' });
  };

  return (
    <section>
      <Space direction="vertical" size={24}>
        <Space size={4}>
          <Button type="ghost" icon={<Icon component={Back} style={{ fontSize: '1.25em' }} />} onClick={handleBack} />
          <Typography.Title>Referrals</Typography.Title>
        </Space>
        <ReferralsTable />
      </Space>
    </section>
  );
};

export default RecentReferrals;
