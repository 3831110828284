import { useStripe } from '@stripe/react-stripe-js';
import { StripeElements } from '@stripe/stripe-js';
import { Button, Col, Input, Row, Space } from 'antd';
import Form from 'antd/es/form';
import { useForm } from 'antd/es/form/Form';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { usePaymentMethodAttach } from '../../../hooks/stripe';
import { JsonResult } from '../../../types';

interface IStripeForm {
  isControlsBlock?: boolean;
  cancelButtonText?: string;
  confirmButtonText?: string;
  options: JsonResult;
  handleNext: (id: string | null) => void;
  handleBack: () => void;
}

const StripeForm = ({
  isControlsBlock,
  cancelButtonText,
  confirmButtonText,
  options,
  handleNext,
  handleBack,
}: IStripeForm) => {
  const [form] = useForm();

  const stripe = useStripe();
  const paymentMethodAttach = usePaymentMethodAttach();

  const [loading, setLoading] = useState<boolean>(false);
  const [isFormCompleted, setIsFormCompleted] = useState<boolean>(false);
  const [elements, setElements] = useState<StripeElements | null>();
  const [isFormLoaded, setIsFormLoaded] = useState<boolean>(false);

  useEffect(() => {
    setElements(stripe?.elements(options));
  }, [stripe]);

  useEffect(() => {
    if (!elements) return;

    const paymentElement = elements?.create('payment', {
      fields: {
        billingDetails: {
          address: {
            country: 'never',
          },
        },
      },
    });

    paymentElement?.mount('#card-element');

    paymentElement?.on('change', (e) => {
      setIsFormCompleted(e.complete);
    });

    paymentElement?.on('loaderstart', () => {
      setIsFormLoaded(true);
    });
  }, [elements]);

  const handleConfirm = () => {
    if (!elements || !stripe) return;

    setLoading(true);

    elements.submit();

    form
      .validateFields()
      .then(({ name }) =>
        stripe.createPaymentMethod({
          elements,
          params: {
            billing_details: {
              name,
              address: {
                country: 'US',
              },
            },
          },
        })
      )
      .then(({ paymentMethod }) =>
        paymentMethodAttach.fetch(undefined, paymentMethod?.id).then(() => handleNext(paymentMethod?.id || null))
      )

      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Space direction="vertical" size={24}>
      <Form form={form} layout="vertical" autoComplete="off">
        {isFormLoaded && (
          <Form.Item name="name" label="Name of card" style={{ marginBottom: 12 }}>
            <Input size="large" placeholder="Enter Card Name" />
          </Form.Item>
        )}
        <Row>
          <Col span={24}>
            <div id="card-element" />
          </Col>
        </Row>
      </Form>
      <Row gutter={24} className={clsx({ 'flex-justify-end': !isControlsBlock })}>
        <Col span={12} className={clsx({ 'w-fit-content': !isControlsBlock })}>
          <Button size="large" type="default" block={isControlsBlock} onClick={handleBack}>
            {cancelButtonText}
          </Button>
        </Col>
        <Col span={12} className={clsx({ 'w-fit-content': !isControlsBlock })}>
          <Button
            size="large"
            type="primary"
            onClick={handleConfirm}
            block={isControlsBlock}
            loading={loading}
            disabled={loading || !isFormCompleted}
          >
            {confirmButtonText}
          </Button>
        </Col>
      </Row>
    </Space>
  );
};

StripeForm.defaultProps = {
  clientSecret: undefined,
  isControlsBlock: false,
  cancelButtonText: 'Cancel',
  confirmButtonText: 'Confirm',
};

export default StripeForm;
