import Layout from '../../../components/Layout/Main';
import Content from '../../../components/Pages/Home/RecentReferrals';
import { displayName } from '../../../config';

const RecentReferrals = (): JSX.Element => {
  document.title = `${displayName}: Referrals`;

  return (
    <Layout>
      <Content />
    </Layout>
  );
};

export default RecentReferrals;
